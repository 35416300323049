html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
* {
  -webkit-tap-highlight-color: transparent !important;
}
* {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  outline: none;
}
*:focus {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

*,
*:before,
*:after {
  /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: border-box;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

textarea:focus,
input:focus {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

html {
  /*overflow-y: scroll;
	overflow: -moz-scrollbars-vertical !important;*/
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 1px;
  margin-bottom: 0.1rem;
}

html,
body {
  min-height: 320px;
  height: auto;
  width: 100%;
}

html,
body {
  background-color: transparent;
}
body {
  min-width: auto;
  word-wrap: break-word;
}
body {
  background-color: #ffffff;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
img {
  height: auto; /* Make sure images are scaled correctly. */
  max-width: 100%; /* Adhere to container width. */
}
figure {
  margin: 1em 0; /* Extra wide images within figure tags don't overflow the content area. */
}
