.menu.start-tight {
  position: absolute;
  left: 0;
  top: $mat-toolbar-height;
  background-color: $deep-blue;
  height: $lateralSidenav-height;
  z-index: 10;
  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0;
  }
  li {
    height: $lateralSidenavLi-height;
    width: $lateralSidenavLi-width;
    border-bottom: 1px solid $white;
    text-align: center;
    margin: 0 auto;
    vertical-align: middle;
    padding-top: 8px;
    position: relative;
    transition: all 0.5s ease-out;
    &:hover {
      background-color: $blue;
      cursor: pointer;
      span.hidden {
        display: block;
        position: absolute;
        left: $lateralSidenavLi-width;
        top: 0;
        background-color: $red;
        padding: 8px;
        font-weight: 700;
        border: 1px solid transparent;
      }
    }
    a {
      color: $white;
      transition: color 0.5s ease;
      span.hidden {
        display: none;
      }
    }

    a:hover {
      color: #f1f1f1;
    }
  }
}
