.sidenav.start-tight {
  height: 100%;
  width: 0;
  position: absolute;
  z-index: 1;
  top: $mat-toolbar-height;
  left: $lateralSidenavLi-width;
  background-color: $white;
  border-right: 1px solid $gray-light;
  overflow-x: hidden;
  transition: width 0.5s ease;
  padding-top: 60px;
  width: 0px;
  height: $lateralSidenav-height;

  &.sidenavOpen {
    width: $lateralSidenav-open;
  }
  a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  a:hover {
    color: #f1f1f1;
  }
  .closebtn {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 24px;
    margin-left: 56px;
  }
  .inner-content {
    padding: 8px;
  }
}
