.margin__auto {
  margin: $margin--auto;
}
.margin__xs {
  margin: $margin--xs;
}
.margin__top--xs {
  margin-top: $margin--xs;
}
.margin__top--s {
  margin-top: $margin--s;
}
.margin__top--m {
  margin-top: $margin--m;
}
.margin__top--l {
  margin-top: $margin--l;
}
.margin__top--xl {
  margin-top: $margin--xl;
}
.margin__top--xxl {
  margin-top: $margin--xxl;
}
.margin__top--xxxl {
  margin-top: $margin--xxxl;
}
.margin__bottom--xs {
  margin-bottom: $margin--xs;
}
.margin__bottom--s {
  margin-bottom: $margin--s;
}
.margin__bottom--m {
  margin-bottom: $margin--m;
}
.margin__bottom--l {
  margin-bottom: $margin--l;
}
.margin__bottom--xl {
  margin-bottom: $margin--xl;
}
.margin__right--s {
  margin-right: $margin--s;
}
