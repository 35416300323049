.section-title--icon {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
}
ul.nav-ul {
  width: auto;
  margin: 0;
  padding: 0;
  border: 0 solid $gray-light;
  display: block;
}
ul.nav-ul li {
  list-style-type: none;
  position: relative;

  cursor: pointer;
}

ul.nav-ul li a {
  text-decoration: none;
  font-size: $font__size--s;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 64px;
  line-height: 64px;
  width: 100%;
  color: $color__text-main;
  background-color: $white;
  transition: all 0.4s ease;
  box-sizing: border-box;
  border: solid #b3b3b3;
  border-width: 1px 0 0;
}

ul.nav-ul li a:hover {
  color: #000;
  background-color: $gray-extralight;
}

ul.nav-ul li a.active {
  background-color: $primary;
  color: $white;
}

ul.nav-ul li a span.icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 0px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
}

/* LEFT and RIGHT menu OVERLAY*/

.overlay-left-label .overlay {
  position: fixed;
  display: none;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: $z__index-top;
  background: rgba(23, 23, 23, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.sidenav-start-left.display ~ .overlay-left-label .overlay {
  display: block !important;
}
