@import '../sass/variables-site/variables-site';

.pdf-report {
  h3.media {
    font-size: 14px;
  }
}
.pdf-stats--title {
  font-size: 12px;
  font-weight: 600;
  span.material-icons {
    color: $primary;
  }
}

.content-data {
  font-size: 12px;
  p {
    font-size: 12px;
  }

  .mat-list-base .mat-list-item .mat-list-item-content {
    justify-content: space-between;
    font-size: 14px;
  }
  .mat-list-base .mat-subheader {
    padding: 0 8px;
    height: 30px;
    color: $black;
    &.red {
      color: $primary;
      font-weight: normal;
    }
  }
  table {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    border-collapse: collapse;
    tr {
      transition: all 0.6s ease;
      cursor: pointer;
      td {
        padding: 4px 8px;
        border-bottom: 1px dotted $gray-light;
      }

      td:nth-child(1) {
        width: 60%;
        font-weight: 700;
        &.red {
          color: $primary;
          font-weight: normal;
        }
      }

      td:nth-child(2),
      td:nth-child(3) {
        text-align: right;
      }
      &:hover {
        background-color: $narrow-blue;
      }
    }
  }
  table.without-border {
    margin-top: 0px;
    tr {
      td {
        border-bottom: none;
      }
    }
  }
  hr.border-dotted-bottom {
    border-bottom: 1px dotted $gray-lighter;
    background-color: transparent;
    margin-top: 16px;
    margin-bottom: 0;
  }
}
.bar-chart {
  position: relative;
  margin: 8px 8px;
  height: 24px;
  .full-percent {
    width: 100%;
    background-color: $gray-extralight;
    height: 16px;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
  }
  .data-percent {
    width: 25%;
    height: 16px;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
  }
  .data-percent.purple {
    background-color: $purple;
  }
  .data-percent.yellow {
    background-color: $yellow;
  }
  .data-percent.green {
    background-color: $green;
  }
  .data-percent.lightgray {
    background-color: $gray-extralight;
  }
}

.bar-chart-data {
  padding: 4px 8px;
  &.row {
    display: flex;
    .column.two {
      flex: 50%;
    }
    .column.three {
      flex: 33%;
    }
  }
  .bar-chart-number {
    margin-bottom: 0px;
  }
  .column {
    p {
      margin: 0;
    }
  }
  small {
    font-size: 10px;
  }
}

.horizontal-bar-chart {
  position: relative;
  margin: 8px 8px;

  .horizontal-bar-chart-row {
    display: block;
    .one {
      display: inline-block;
      width: 36%;
    }
    .two {
      display: inline-block;
      width: 48%;
      vertical-align: middle;
    }
    .three {
      display: inline-block;
      width: 16%;
      text-align: right;
    }
  }
  .horizontal-bar {
    height: 16px;
  }
  .yellow {
    background-color: $yellow;
  }
  .purple {
    background-color: $purple;
  }
  .green {
    background-color: $green;
  }
}
