@import '~@angular/material/theming';

/*http://mcg.mbitson.com/#!?mcgpalette0=%233f51b5*/

$angular-theme-primary: mat-palette($theme-blue);
//$angular-theme-accent: mat-palette($theme-red);
$angular-theme-accent: mat-palette($theme-green);

// The warn palette is optional (defaults to red).
$angular-theme-warn: mat-palette($theme-orange);

// Create the theme object (a Sass map containing all of the palettes).
$angular-theme-colors: mat-light-theme(
  $angular-theme-primary,
  $angular-theme-accent,
  $angular-theme-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($angular-theme-colors);
