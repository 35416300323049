.leaflet-pane {
  z-index: $z__index-zero;
}

.leaflet-pane.leaflet-popup-pane {
  z-index: $z__index-top;
}

/* DON´T CHANGE, viewport height*/
.map-container {
  position: relative;
  display: table;
  width: 100%;
  height: 88vh; // calc(100hw)
  min-height: 320px;
  margin-top: 1px;
  background-color: rgb(238, 238, 238);
  z-index: $z__index-zero;
}

.pdf-map-container {
  position: relative;
  display: table;
  width: 100%;
  height: 400px; // calc(100hw)
  min-height: 320px;
  margin-top: 1px;
  background-color: rgb(238, 238, 238);
  z-index: $z__index-zero;
}

.map-frame,
.pdf-map-frame {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
}

#map_elementRef,
.map_elementRef,
.map-area {
  height: 100%;
  width: 100%;
}

.map-elementRef {
  height: 100%;

  .info {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }

  .info h4 {
    margin: 0 0 5px;
    color: #777;
  }

  .legend {
    line-height: 18px;
    color: #555;
  }

  .legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
  }
}

.leaflet-container {
  font-family: $font__main;
  color: $color__text-main;
  font-size: $font__size--s;
  line-height: $line__height-factor * $font__size--s;

  .customPopUp {
    bottom: 76px !important;
    left: -82px !important;

    .leaflet-popup-content-wrapper {
      padding-bottom: 40px;

      .leaflet-popup-content {
        width: auto !important;

        p {
          font-family: $font__main;
          font-size: 12px;
          margin: 4px 0;
        }
      }
    }
  }


}


.leaflet-control-container .info.leaflet-control {
  margin: 4px 4px;
}
