.breadcrumbs-icons {
  margin-left: 8px;
  width: 20%;
  height: 56px;
  background-color: $primary;
  color: white;
  border-radius: 4px;
  text-align: center;
  display: block;
}
