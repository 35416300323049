/*
// base (ligthgray)
button.mat-button-base,
button.base,
.mat-flat-button.lightgray,
.mat-raised-button.lightgray,
.mat-fab.lightgray,
.mat-mini-fab.lightgray,
button.lightgray {
  background-color: $gray-extralight;
}

button.mat-button-base:hover,
button.base:hover,
.mat-flat-button.lightgray:hover,
.mat-raised-button.lightgray:hover,
.mat-fab.lightgray:hover,
.mat-mini-fab.lightgray:hover,
button.lightgray:hover,
button.mat-button:hover,
button.mat-raised-button:hover,
button.mat-stroked-button:hover,
button.mat-flat-button:hover,
button.mat-icon-button:hover {
  background-color: $gray-lighter;
}

// basic (white)

.mat-flat-button.basic,
.mat-raised-button.basic,
.mat-fab.basic,
.mat-mini-fab.basic,
button.basic {
  background-color: $white;
  border: 1px solid lightgray;
}
.mat-flat-button.basic:hover,
.mat-raised-button.basic:hover,
.mat-fab.basic:hover,
.mat-mini-fab.basic:hover,
button.basic {
  background-color: $gray-extralight;
  color: $black;
}

// primary
button.primary {
  color: $white;
  font-weight: bold;
  background-color: $primary;
}

button.primary:hover,
button.mat-primary:hover {
  background: $primary--deep;
  cursor: pointer;
  !*
  -webkit-box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.25);
  *!
}

//warn
button.warn {
  background-color: $warn;
}
button.mat-warn:hover,
button.warn:hover {
  background: $warn---deep;
}
button.button--m.mat-raised-button.mat-button-base.mat-warn,
button.mat-raised-button.mat-warn {
  color: white;
}

//accent
button.mat-accent {
  background: $accent;
  color: $white;
  font-weight: bold;
}
button.mat-accent:hover,
button.mat-accent:hover {
  background: $accent---deep;
}

//icons
button.mat-icon-button.close {
  background: white;
}

button.mat-icon-button.close:hover {
  background: $gray-lighter;
}

// misc
button.mat-button-base.menu {
  background: transparent;
}

// misc
button.mat-button-base.top {
  background: transparent;
  color: $white;
  opacity: 1;
}
button.mat-button-base.top:hover {
  opacity: 0.8;
}

.mat-flat-button.mat-button-base.mat-button-disabled:hover {
  background: rgba(0, 0, 0, 0.12);
}

button.no-hover-effect:hover {
  background: inherit;
  cursor: pointer;
}
*/
