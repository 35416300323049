@import '~@angular/material/theming';
@import '../sass/variables-site/variables-site';
// customize Angular material components here!!
.mat-form-field {
  width: 100%;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 32px;
}

.accordion .mat-expansion-panel {
  margin-bottom: 4px;
}
.accordion .mat-expansion-panel-header {
  padding: 0 8px;
  background: $light-purple;
  height: 40px;
  transition: all 0.4s ease;
}
.accordion .mat-expansion-panel-body {
  padding: 0 0px 16px !important;
}

.accordion td.mat-cell:last-of-type {
  padding-right: 0;
}
.mat-checkbox-layout {
  overflow: visible;
}
.stages-mat-tab {
  img.brand {
    width: 100%;
    min-width: 80px;
  }
  .mat-tab-label {
    min-width: 116px;
    padding: 0 16px;
    opacity: 1;
  }

  .mat-tab-body-content {
    margin: 0px auto;
    overflow: hidden;
  }
  .mat-tab-body.mat-tab-body-active {
    overflow-y: hidden;
  }
}
