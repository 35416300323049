.mat-snack-bar-container {
  &.danger {
    background: lighten($red, 15);
    color: white;
  }

  &.wide {
    max-width: 90%;
    width: auto;
  }
}
