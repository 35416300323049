@charset "utf-8"; /* You can add global styles to this file, and also import other style files */
@import '../../../libs/ui/src/lib/sass/global-styles';

@import './app/sass/variables-site/variables-site';
//@import './app/sass/mixins/mixins';
@import './app/sass/color-theme';
@import '../../../libs/ui/src/lib/sass/color-classes';

@import "./app/sass/initialize";

@import './app/sass/layout/layout';
@import './app/sass/typography/typography';

@import './app/sass/elements/elements';
@import './app/sass/page-structure/page-structure';
@import './app/sass/navigation/navigation';

// customize
@import './app/sass/custom-material';
@import './app/sass/custom-leaflet';

@import './app/sass/lib-layout';

// pdf report
@import './app/sass/pdf-report';
// pdf stats
@import './app/sass/pdf-stats';

// business-form
@import './app/sass/business-form';

// crossfilter view
@import './app/sass/crossfilter';

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.50rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}
