$font__path: '/assets/fonts/';
$font__main: Plex, Arial, 'Helvetica Neue', Helvetica, Verdana, sans-serif;
$font__code: Monaco, Consolas, 'Andale Mono', 'DejaVu Sans Mono', monospace;
$font__pre: 'Courier 10 Pitch', Courier, monospace;
$font__line-height-body: 1.5;
$font__line-height-pre: 1.6;

// fonts Skeleton
$font__size-base: 62.5% !default;
$font__size-large: ceil(($font__size-base * 1.25)) !default; // ~18px
$font__size-small: ceil(($font__size-base * 0.85)) !default; // ~12px

$font__color-text: rgba($gray-dark, 1);

$line__height-factor: 1.5;

$font__size--xs: 12px;
$font__size--s: 14px;
$font__size--m: 16px;
$font__size--l: 18px;
$font__size--xl: 24px;
$font__size--xxl: 32px;
