$mat-gt-xs: "screen and(min-width: 600px)";
$mat-gt-sm: "screen and (min-width: 960px)";
$mat-gt-md: "screen and(min-width: 1280px)";
$mat-xs: "screen and (max-width: 599px)";
$mat-lt-md: "screen and( max-width: 959px)";
$mat-lt-lg: "screen and (max-width: 1279px)";
/*
$mat-xs: screen and (max-width: 599px);
$mat-sm: screen and (min-width: 600px) and (max-width: 959px);
$mat-md: screen and (min-width: 960px) and (max-width: 1279px);
$mat-lg: screen and (min-width: 1280px) and (max-width: 1919px);
$mat-xl: screen and (min-width: 1920px) and (max-width: 5000px);
$mat-lt-sm: screen and (max-width: 599px);
$mat-lt-md: screen and(max-width: 959px);
*/

/*
$mat-lt-lg: screen and (max-width: 1279px);
$mat-lt-xl: screen and (max-width: 1919px);
*/

/*
$mat-gt-xl: screen and (min-width: 1920px);
*/

// usage

/* > 600px*/
//@media #{$mat-gt-xs} {}
/* > 900px*/
//@media #{$mat-gt-sm} {}
