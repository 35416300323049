$primary: $blue;
$primary--deep: $deep-blue;

$primary--light: $sky;

$secondary: $orange;
$secondary--deep: $deep-orange;

$tertiary: $yellow;
$tertiary--deep: $deep-yellow;

$accent: $red;
$accent---deep: $deep-red;

$warn: $green;
$warn---deep: $deep-green;

$color__background-body: $white;
$color__background-screen: $gray-light;
$color__background-hr: #cccccc;
$color__background-button: #e6e6e6;
$color__background-pre: #eee;
$color__background-ins: #fff9c0;

$color__text-screen: $sky;
$color__text-input: $gray-dark;
$color__text-input-focus: $gray;
$color__link: $primary;
$color__link-visited: $blue;
$color__link-hover: $deep-blue;
$color__text-main: rgba(32, 32, 32, 1);

$color__border-button: #ccc #ccc #bbb;
$color__border-button-hover: #ccc #bbb #aaa;
$color__border-button-focus: #aaa #bbb #bbb;
$color__border-input: #ccc;
$color__border-abbr: $gray-dark;
