.text__align--left {
  text-align: left;
}
.text__align--center {
  text-align: center;
}
.text__align--right {
  text-align: right;
}
.text__align--justify {
  text-align: justify;
}
