$size__site-main: 100%;
$size__site-sidebar: 25%;

// content width
$size__max-width: 1420px;
$size__med-width: 720px;
$estudio-sidenav-width: 320px;

// toolbar
$mat-toolbar-height: 40px;
$toolbar-height: 56px;
$searchbox-height: 48px;
$searchbox-width: 380px;
$searchbox-radius: 4px;

$sidenav-data-width--gt: 320px;
$sidenav-data-width--xs: 100%;

$fixed-width: 64px;
$fixed-height: $fixed-width * 1.4;

// lateral sidenav
$lateralSidenav-open: 420px;
$lateralSidenav-height: 100vh;
$lateralSidenavLi-width: 40px;
$lateralSidenavLi-height: 40px;
