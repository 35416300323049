.business-form {
  button,
  input,
  select,
  optgroup,
  textarea {
    width: 100%;
  }

  button.mat-focus-indicator.button--xs.mat-flat-button.mat-button-base.mat-warn {
    width: 72px;
  }

  .checkbox-container div {
    margin-top: 8px;
  }
  .mat-checkbox-layout {
    display: block;
    white-space: normal;
  }
}
