#charts {
  position: absolute;
  z-index: 9;
  background: white;
  border-radius: 7px;
  width: 90vw;
  margin: auto;
  left: 50%;
  bottom: 85px;
  transform: translate(-50%, 0);
  overflow: auto;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

  #dateChart {
    .title {
      margin: 1rem;
      text-align: center;
    }

    svg {
      .axis.y {
        display: none !important;
      }
    }
  }
}


.dc-chart path.dc-symbol, .dc-legend g.dc-legend-item.fadeout {
  fill-opacity: 0.5;
  stroke-opacity: 0.5;
}

.dc-chart rect.bar {
  stroke: none;
  cursor: pointer;
}

.dc-chart rect.bar:hover, .dc-chart rect.bar:focus {
  fill-opacity: .5;
}

.dc-chart rect.deselected {
  stroke: none;
  fill: #ccc;
}

.dc-chart .pie-slice {
  fill: #fff;
  font-size: 12px;
  cursor: pointer;
}

.dc-chart .pie-slice.external {
  fill: #000;
}

.dc-chart .pie-slice:focus {
  fill-opacity: .8;
}

:hover .dc-chart .pie-slice.highlight {
  fill-opacity: .8;
}

.dc-chart .pie-path {
  fill: none;
  stroke-width: 2px;
  stroke: #000;
  opacity: 0.4;
}

.dc-chart .selected path, .dc-chart .selected circle {
  stroke-width: 3;
  stroke: #ccc;
  fill-opacity: 1;
}

.dc-chart .deselected path, .dc-chart .deselected circle {
  stroke: none;
  fill-opacity: .5;
  fill: #ccc;
}

.dc-chart .axis path, .dc-chart .axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

.dc-chart .axis text {
  font: 10px sans-serif;
}

.dc-chart .grid-line, .dc-chart .axis .grid-line, .dc-chart .grid-line line, .dc-chart .axis .grid-line line {
  fill: none;
  stroke: #ccc;
  shape-rendering: crispEdges;
}

.dc-chart .brush rect.selection {
  fill: #4682b4;
  fill-opacity: .125;
}

.dc-chart .brush .custom-brush-handle {
  fill: #eee;
  stroke: #666;
  cursor: ew-resize;
}

.dc-chart path.line {
  fill: none;
  stroke-width: 1.5px;
}

.dc-chart path.area {
  fill-opacity: .3;
  stroke: none;
}

.dc-chart path.highlight {
  stroke-width: 3;
  fill-opacity: 1;
  stroke-opacity: 1;
}

.dc-chart g.state {
  cursor: pointer;
}

.dc-chart g.state :hover, .dc-chart g.state :focus {
  fill-opacity: .8;
}

.dc-chart g.state path {
  stroke: #fff;
}

.dc-chart g.deselected path {
  fill: #808080;
}

.dc-chart g.deselected text {
  display: none;
}

.dc-chart g.row rect {
  fill-opacity: 0.8;
  cursor: pointer;
}

.dc-chart g.row rect:hover, .dc-chart g.row rect:focus {
  fill-opacity: 0.6;
}

.dc-chart g.row text {
  fill: #fff;
  font-size: 12px;
  cursor: pointer;
}

.dc-chart g.dc-tooltip path {
  fill: none;
  stroke: #808080;
  stroke-opacity: .8;
}

.dc-chart g.county path {
  stroke: #fff;
  fill: none;
}

.dc-chart g.debug rect {
  fill: #00f;
  fill-opacity: .2;
}

.dc-chart g.axis text {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.dc-chart .node {
  font-size: 0.7em;
  cursor: pointer;
}

.dc-chart .node :hover, .dc-chart .node :focus {
  fill-opacity: .8;
}

.dc-chart .bubble {
  stroke: none;
  fill-opacity: 0.6;
}

.dc-chart .highlight {
  fill-opacity: 1;
  stroke-opacity: 1;
}

.dc-chart .fadeout {
  fill-opacity: 0.2;
  stroke-opacity: 0.2;
}

.dc-chart .box text {
  font: 10px sans-serif;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.dc-chart .box line {
  fill: #fff;
}

.dc-chart .box rect, .dc-chart .box line, .dc-chart .box circle {
  stroke: #000;
  stroke-width: 1.5px;
}

.dc-chart .box .center {
  stroke-dasharray: 3, 3;
}

.dc-chart .box .data {
  stroke: none;
  stroke-width: 0px;
}

.dc-chart .box .outlier {
  fill: none;
  stroke: #ccc;
}

.dc-chart .box .outlierBold {
  fill: red;
  stroke: none;
}

.dc-chart .box.deselected {
  opacity: 0.5;
}

.dc-chart .box.deselected .box {
  fill: #ccc;
}

.dc-chart .symbol {
  stroke: none;
}

.dc-chart .heatmap .box-group.deselected rect {
  stroke: none;
  fill-opacity: 0.5;
  fill: #ccc;
}

.dc-chart .heatmap g.axis text {
  pointer-events: all;
  cursor: pointer;
}

.dc-chart .empty-chart .pie-slice {
  cursor: default;
}

.dc-chart .empty-chart .pie-slice path {
  fill: #fee;
  cursor: default;
}

.dc-data-count {
  float: right;
  margin-top: 15px;
  margin-right: 15px;
}

.dc-data-count .filter-count, .dc-data-count .total-count {
  color: #3182bd;
  font-weight: bold;
}

.dc-legend {
  font-size: 11px;
}

.dc-legend .dc-legend-item {
  cursor: pointer;
}

.dc-legend g.dc-legend-item.selected {
  fill: blue;
}

.dc-hard .number-display {
  float: none;
}

div.dc-html-legend {
  overflow-y: auto;
  overflow-x: hidden;
  height: inherit;
  float: right;
  padding-right: 2px;
}

div.dc-html-legend .dc-legend-item-horizontal {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

div.dc-html-legend .dc-legend-item-horizontal.selected {
  background-color: #3182bd;
  color: white;
}

div.dc-html-legend .dc-legend-item-vertical {
  display: block;
  margin-top: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  cursor: pointer;
}

div.dc-html-legend .dc-legend-item-vertical.selected {
  background-color: #3182bd;
  color: white;
}

div.dc-html-legend .dc-legend-item-color {
  display: table-cell;
  width: 12px;
  height: 12px;
}

div.dc-html-legend .dc-legend-item-label {
  line-height: 12px;
  display: table-cell;
  vertical-align: middle;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 0.75em;
}

.dc-html-legend-container {
  height: inherit;
}
