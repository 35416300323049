@import '../sass/variables-site/variables-site';
// PDF report scss
.pdf-report {
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  body {
    background: $color__background-screen;
    margin: 0;
    padding: 0;
    color: $color__text-main;
  }

  div[data-size='A4'] {
    position: relative;
    background-color: $color__background-body;
    /* DEV API*/
    /*background-image: url('../../../assets/img/pdf/20180118-layout.webp');*/
    background-repeat: no-repeat;
    background-size: contain;
    width: 21cm;
    height: 29.7cm;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm rgba($color__background-screen, 0.5);
    padding: 0px 56px;
    clear: both;
  }
  p {
    font-size: 12px;
    line-height: 18px;
  }

  address {
    font-size: 12px;
    padding-top: 20px;
    font-style: normal;
  }
  footer {
    position: absolute;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    bottom: 0px;
    left: 0;
    right: 0;
    font-size: 12px;
    background-color: transparent;
  }
  div.hidden {
    display: none;
  }
  .page_number {
    font-size: 10px;
  }
  @media print {
    * {
      -webkit-print-color-adjust: exact !important;
      color-adjust: exact !important;
    }
    @page {
      margin: 0;
      size: A4 portrait;
    }
    body,
    div[data-size='A4'] {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
      margin: 0;
      box-shadow: 0;
    }

    div[data-size='A4'] {
      background-image: none;
    }
    .hidden {
      display: none;
    }
  }
}
