.login-container,
.copyright-container {
  position: relative;
  z-index: 10;
}
.copyright-container {
  text-align: center;
  margin: 0px;
  position: absolute;
  bottom: 0;
  p {
    font-weight: 700;
    color: #ffffff;
  }
}
@media #{ $mat-gt-xs } {
  .copyright-container {
    margin: 420px auto 0 auto;
  }
}

form.login-form {
  input:-internal-autofill-selected {
    background-color: transparent !important;
  }
  color: $white;
  font-weight: 700;
  padding: 8px;
  p {
    color: $white;
    font-weight: 700;
  }
  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $white;
    font-weight: 700;
  }
  .mat-input-element {
    caret-color: $white;
    font-weight: 700;
    background-color: transparent;
  }
  .mat-form-field-invalid .mat-input-element {
    color: $white;
  }
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $white;
  }
  .login-button-container {
    margin: 0 auto 0 auto;
    button.login-button {
      width: 120px;
      margin: 16px 0;
    }
  }
  @media #{ $mat-gt-xs } {
    .login-button-container {
      margin: 0 0 0 0;
      width: 120px;
    }
  }

  .login-content {
    color: #ffffff;
    font-weight: 700;
    padding: 16px 16px 8px 16px;
  }

  /* Applying the animation */
  .errors {
    color: $green;
    animation-name: shakeMe;
    animation-duration: 0.5s;
  }
}
/* > 600px*/

/* > 900px*/
@media #{ $mat-gt-sm } {
}
